import "./App.css";
import { EmployeementBgc } from "./components/EmployeementBgc";

function App() {
  return (
    <div className="App">
      <EmployeementBgc />
    </div>
  );
}

export default App;
